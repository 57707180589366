<template>
  <div class="userFooter-container">
    <button>首页</button>
    <button>上一页</button>
    <button>下一页</button>
    <button>末页</button>
  </div>
</template>

<script>
export default {
  name: 'userFooter',

}
</script>

<style scoped lang="scss">
.userFooter-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 328px;
  height: 28px;
  margin: 70px auto;
  display: flex;
  justify-content: space-between;

  button {
    width: 78px;
    height: 28px;
    background: linear-gradient(125deg, #009FFF 0%, #05377B 100%);
    box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000;
    opacity: 1;
    border-radius: 20px;
    font-size: 14px;
    font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
    font-weight: 800;
    color: #009FFF;
    line-height: 28px;
  }
}
</style>