<template>
    <div class="d-flex">

        <!-- 内容区域   -->
        <div class="content mt-4">
            <div>
                <UserHeader :title="'我的背包'" />
                <div class="pack-content">
                    <div @click="allList('1')" :class="status == '1' ? 'actived family' : 'actives family'" class="family">
                        全选</div>
                    <div @click="recycle" style="pointer-events: none;" class="unactive family">升序</div>
                    <div @click="recycle('2')" :class="status == '2' ? 'actived family' : 'actives family'" class="family">
                        分解</div>
                    <div @click="extract('3')" :class="status == '3' ? 'actived family' : 'actives family'" class="family">
                        提货</div>
                    <!-- <div class=" px-lg-4 px-2 mr-2 py-1 cursor-pointer" @click="allList">
                        全选
                    </div>
                    <div class=" px-lg-4 px-2 mr-2 py-1 cursor-pointer" @click="recycle">
                        升序
                    </div>
                    <div class=" px-lg-4 px-2 mr-2 py-1 cursor-pointer" @click="extract">
                        分解
                    </div>
                    <div class=" px-lg-4 px-2 mr-2 py-1 cursor-pointer" @click="extract">
                        提货
                    </div> -->
                    <!-- <div class=" px-lg-4 px-2 mr-2 py-1 cursor-pointer border-yellow">
                        武器总件数：{{ total }}
                    </div> -->
                    <!-- <div class=" px-lg-4 mr-2 py-1 d-sm-block d-none cursor-pointer border-yellow">
                        当前页武器总价：{{ money || 0 }}
                    </div> -->
                </div>
            </div>
            <div style="text-align:center;margin:10px 0;font-size:20px;">
                <span>{{ this.recyleMoney.index }}件物品</span><span class="f-yellow-color ml-2">${{ this.recyleMoney.benr
                }}</span>
            </div>
            <div class="flex-wrap row mt-3 d-flex">
                <div class="content-hover col-lg-2 col-md-2 col-sm-3 col-4 mb-3 px-1" @click="itemOne(item, index)"
                    v-for="(item, index) in StorageList" :key="index">
                    <weapon class="position-relative" :class="item.isShow ? 'active' : ''" :res-data="item"
                        style="height:180px; width:120px;"> </weapon>
                </div>
                <!-- 为空的时候显示   -->
                <vacancy v-if="StorageList.length == 0" />

            </div>
            <!-- <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
                @current-change="currentChange">
            </el-pagination> -->
        </div>


        <!-- 背包物品 - 显示退回原因   -->
        <el-dialog custom-class="pop-up" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false"
            :visible.sync="ReturnReason" width="700px">
            <div class="p-4 text-white">
                <div>
                    <p>
                        {{ back_message }}
                    </p>
                </div>
                <div class=" ml-auto color-dark-brown" style="width: 180px">
                    <bottom-bg @click.native="ReturnReason = false" title="确认"></bottom-bg>
                </div>
                <div @click="ReturnReason = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
    <!-- <div class="packsack-container">
        <UserHeader :title="'我的背包'" />
        <div class="packsack-profile">
            <button>全选</button>
            <button>升序</button>
            <button>分解</button>
            <button>提取</button>
        </div>
        <ul class="picture-container">
            <li>
                <div class="txt">CS:GO 10周年印花胶囊</div>
                <div class="empty"></div>
                <div class="icon">
                    <img src="@/assets/image/userCenter/u8_icon.png" alt="">
                    <span>16.8</span>
                </div>
            </li>
            <li>
                <div class="txt">CS:GO 10周年印花胶囊</div>
                <div class="empty"></div>
                <div class="icon">
                    <img src="@/assets/image/userCenter/u8_icon.png" alt="">
                    <span>16.8</span>
                </div>
            </li>
            <li>
                <div class="txt">CS:GO 10周年印花胶囊</div>
                <div class="empty"></div>
                <div class="icon">
                    <img src="@/assets/image/userCenter/u8_icon.png" alt="">
                    <span>16.8</span>
                </div>
            </li>
            <li>
                <div class="txt">CS:GO 10周年印花胶囊</div>
                <div class="empty"></div>
                <div class="icon">
                    <img src="@/assets/image/userCenter/u8_icon.png" alt="">
                    <span>16.8</span>
                </div>
            </li>
            <li>
                <div class="txt">CS:GO 10周年印花胶囊</div>
                <div class="empty"></div>
                <div class="icon">
                    <img src="@/assets/image/userCenter/u8_icon.png" alt="">
                    <span>16.8</span>
                </div>
            </li>
            <li>
                <div class="txt">CS:GO 10周年印花胶囊</div>
                <div class="empty"></div>
                <div class="icon">
                    <img src="@/assets/image/userCenter/u8_icon.png" alt="">
                    <span>16.8</span>
                </div>
            </li>
        </ul>
        <UserFooter />
    </div> -->
</template>

<script>
import bottomBg from "@/components/bottomBg";
import weapon from "./common/weapon";
import $api from '@/api/user/index'
import UserHeader from "@/components/userCenter/userHeader.vue";
import UserFooter from "@/components/userCenter/userFooter.vue";
export default {
    name: "index",
    components: {
        bottomBg,
        weapon,
        UserHeader,
        UserFooter
    },
    data() {
        return {
            ReturnReason: false,
            StorageList: [],
            total: 0,
            pageSize: 0,
            back_message: '',
            sequenceTab: false,
            status: 0
        }
    },
    computed: {
        money() {
            let benr = 0
            this.StorageList.forEach(item => {
                benr += (item.bean) * 1
            })
            return benr.toFixed(2)
        },
        recyleMoney() {
            let benr = 0
            let index = 0
            this.StorageList.forEach(item => {
                if (item.isShow == true) {
                    benr += (item.bean) * 1
                    index++
                }
            })
            return { benr: benr.toFixed(2), index }
        }

    },
    created() {
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getStorage()
        },
        async sequence() {
            let sort = this.sequenceTab ? '0' : '1'

            await this.getStorage(1, sort)
            setTimeout(() => { this.sequenceTab = !this.sequenceTab }, 100)

        },
        shoppinng(item, index) {
            this.ReturnReason = true
            console.log(item, index)
        },
        async getStorage(page = 1) {
            try {
                let { data, code } = await $api.getStorage(page)
                if (code == 500) return
                data.data.forEach(item => {
                    if (item.back_message != '') {
                        console.log(123)
                    }
                    item.isShow = false
                })
                this.StorageList = data.data
                this.total = data.total
                this.pageSize = data.per_page
            } catch (e) {
                console.log(e)
            }
        },
        currentChange(val) {
            this.getStorage(val)
            window.scrollTo(0, 0);
        },
        allList(val) {
            this.status = val;
            this.StorageList.forEach(item => {
                item.isShow = !item.isShow
            })
        },
        itemOne(item) {
            if (item.back_message != '') {
                this.ReturnReason = true
                this.back_message = item.back_message
            }
            item.isShow = !item.isShow
        },
        async recycle(val) {
            this.status = val;
            let arr = []
            try {
                this.StorageList.forEach(item => {
                    if (item.isShow) {
                        arr.push(item.id)
                    }
                })
                let { message, code } = await $api.getCash({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                }

                this.getStorage()
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },
        async extract(val) {
            this.status = val;
            let arr = []
            this.StorageList.forEach(item => {
                if (item.isShow) {
                    arr.push(item.id)
                }
            })
            try {
                let { message, code } = await $api.getExtract({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                }
                this.getStorage()
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },

    }
}
</script>

<style scoped lang="scss">
/deep/.header-title {
    font-size: 28px;
    font-style: italic;
    -webkit-text-stroke: 1px rgba(159, 218, 53, 1);
    color: RGBA(5, 55, 123, 1);
}

.pack-content {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actives {
        color: RGBA(0, 159, 255, 1);
        width: 78px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        background-image: url(../../assets/image/13.png);
        background-size: 100% 100%;
    }

    .actived {
        color: RGBA(0, 159, 255, 1);
        width: 78px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        background-image: url(../../assets/image/14.png);
        background-size: 100% 100%;
    }

    .unactive {
        color: RGBA(0, 159, 255, 1);
        width: 78px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        background-image: url(../../assets/image/13.png);
        background-size: 100% 100%;
    }
}

.side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
    width: 80%;
    margin: 0 auto;

    @media (max-width: 1200px) {
        width: 96%;
        font-size: 12px;
    }

    &-hover {
        & .active {
            position: relative;

            &::before {
                content: url("../../assets/img/hover.png");
                width: 20px;
                height: 20px;
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 999;
            }
        }
    }
}

.packsack-container {
    /deep/.header-title {
        font-style: italic;
        -webkit-text-stroke: 0.5px rgba(159, 218, 53, 1);
        color: transparent;
    }

    .packsack-profile {
        display: flex;
        justify-content: space-between;
        margin: 25px auto;
        width: 328px;
        height: 28px;

        button {
            width: 78px;
            height: 28px;
            background: linear-gradient(125deg, #009FFF 0%, #05377B 100%);
            box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000;
            opacity: 1;
            border-radius: 20px;
            font-size: 14px;
            font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
            font-weight: 800;
            color: #009FFF;
            line-height: 28px;
        }
    }

    .picture-container {
        width: 300px;
        height: 310px;
        margin: 25px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
            width: 94px;
            height: 150px;
            background: linear-gradient(180deg, #9FDA35 0%, rgba(159, 218, 53, 0) 100%);
            border-radius: 18px;
            opacity: 1;

            .txt {
                width: 80px;
                height: 28px;
                font-size: 12px;
                margin: 4px auto;
                text-align: center;

            }

            .empty {
                width: 80px;
                height: 80px;
                margin: auto;
                border-radius: 18px;
                background-color: white;
            }

            .icon {
                width: 60px;
                height: 22px;
                margin: 8px auto;
                display: flex;
                justify-content: space-between;

                img {
                    width: 20px;
                    height: 20px;
                }

                span {
                    font-size: 18px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: 800;
                }
            }
        }
    }

}
</style>
